.main-info-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../../assets/backgrounds/background-main.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  color: white;
  overflow: hidden;
  .flex-table {
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .info-field {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px 40px;
    font-size: 14px;
    text-align: left;
  }
  .main {
    width: 316px;
    .info-field {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 0 20px 0;
    }
  }
  .side {
    margin-left: 10px;
  }
  .topSpacing {
    margin-top: 10px;
  }
  .bottom {
    margin-top: 10px;
  }
  .flex-table.secondary {
    display: flex;
    align-self: flex-end;
    width: auto;
    margin: 0;
    margin-bottom: 8vh;
    .info-field {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      text-align: right;
      .info-field p {
        line-height: 20px;
      }
    }
    .extras {
      display: flex;
      padding: 0 28px;
      display: flex;
      .extra {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      p {
        font-size: 13px;
        padding: 0px;
        margin: 10px 0;
      }
      .icon {
        width: 12px;
        height: 12px;
        margin-left: 6px;
        margin-right: 24px;
        margin-top: -6px;
        fill: white;
      }
      .icon.right {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 880px) {
  .main-info-page {
    .flex-table {
      flex-direction: column;
    }
    .main {
      .info-field {
        width: 500px;
        padding: 0 40px;
      }
    }
    .side {
      margin-left: 0;
      .info-field {
        width: 500px;
        margin-top: 5px;
      }
    }
    .flex-table.secondary {
      margin: auto;
      align-self: auto;
      .info-field {
        width: 500px;
        margin-top: 5px;
        text-align: center;
      }
      .extras {
        display: none;
      }
    }
  }
}

@media (max-width: 590px) {
  .main-info-page {
    .info-field {
      padding: 20px 30px;
    }
    .main  {
      width: auto;
      margin: 0 2px;
      .info-field {
        width: auto;
      }
    }
    .side {
      margin: 0 2px;
      .info-field {
        width: auto;
      }
    }
    .flex-table.secondary {
      margin: 0 2px;
      .info-field {
        width: auto;
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-7 0:34:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-bottom-fwd
 * ----------------------------------------
 */

 .swing-in-bottom-fwd {
	-webkit-animation: swing-in-bottom-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-bottom-fwd 0.9s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.slide-in-br {
	-webkit-animation: slide-in-br 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-br 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-2 {
  animation-delay: 1s;
}
.delay-3 {
  animation-delay: 1.35s;
}

@-webkit-keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}

@keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
